import React, { useState } from 'react';
import { Menu, Close as X, Add as Plus, Notifications as Bell, Person as User, Help as HelpCircle, Search, Refresh as RefreshCw, BarChart as BarChart2, PieChart, PhoneAndroid as Smartphone, Timeline as Activity, FilterList as Filter } from '@mui/icons-material';
import { FiMenu, FiX, FiPlus, FiBell, FiUser, FiHelpCircle, FiSearch, FiRefreshCw, FiBarChart2, FiPieChart, FiSmartphone, FiActivity, FiFilter } from 'react-icons/fi';

import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

// Mock data for the chart
const data = [
  { name: 'Jan', tests: 400 },
  { name: 'Feb', tests: 300 },
  { name: 'Mar', tests: 600 },
  { name: 'Apr', tests: 800 },
  { name: 'May', tests: 500 },
  { name: 'Jun', tests: 700 },
];


const EnhancedMobileTestingPlatform = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isAIChatOpen, setIsAIChatOpen] = useState(false);
  const [selectedTimeRange, setSelectedTimeRange] = useState('This Week');
  const [isDeviceModalOpen, setIsDeviceModalOpen] = useState(false);
  const [selectedDevices, setSelectedDevices] = useState([]);

  // Temporary data for devices
  const devices = [
    { id: 1, name: 'iPhone 12', os: 'iOS', version: '14.5', status: 'Available' },
    { id: 2, name: 'Samsung Galaxy S21', os: 'Android', version: '11', status: 'In Use' },
    { id: 3, name: 'Google Pixel 5', os: 'Android', version: '12', status: 'Available' },
    { id: 4, name: 'iPad Pro', os: 'iOS', version: '14.6', status: 'Available' },
    { id: 5, name: 'OnePlus 9', os: 'Android', version: '11', status: 'Maintenance' }
  ];

  const toggleDeviceSelection = (deviceId) => {
    setSelectedDevices(prev => 
      prev.includes(deviceId) 
        ? prev.filter(id => id !== deviceId)
        : [...prev, deviceId]
    );
  };

  const DeviceSelectionModal = () => (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full" onClick={() => setIsDeviceModalOpen(false)}>
      <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white" onClick={e => e.stopPropagation()}>
        <div className="mt-3 text-center">
          <h3 className="text-lg leading-6 font-medium text-gray-900">Select Devices</h3>
          <div className="mt-2 px-7 py-3">
            <div className="mb-4 flex justify-between items-center">
              <div className="flex items-center">
                <Filter className="h-5 w-5 text-gray-400 mr-2" />
                <input 
                  className="border rounded px-2 py-1 text-sm" 
                  placeholder="Filter devices..." 
                />
              </div>
              <button className="text-sm text-indigo-600 hover:text-indigo-800">
                <RefreshCw className="h-4 w-4 inline mr-1" />
                Refresh
              </button>
            </div>
            {devices.map(device => (
              <div key={device.id} className="flex items-center justify-between p-2 hover:bg-gray-100 rounded">
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    checked={selectedDevices.includes(device.id)}
                    onChange={() => toggleDeviceSelection(device.id)}
                    className="form-checkbox h-5 w-5 text-indigo-600"
                  />
                  <div className="ml-3">
                    <p className="text-sm font-medium text-gray-900">{device.name}</p>
                    <p className="text-xs text-gray-500">{device.os} {device.version}</p>
                  </div>
                </div>
                <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                  device.status === 'Available' ? 'bg-green-100 text-green-800' :
                  device.status === 'In Use' ? 'bg-yellow-100 text-yellow-800' :
                  'bg-red-100 text-red-800'
                }`}>
                  {device.status}
                </span>
              </div>
            ))}
          </div>
          <div className="items-center px-4 py-3">
            <button
              id="ok-btn"
              className="px-4 py-2 bg-indigo-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500"
              onClick={() => setIsDeviceModalOpen(false)}
            >
              OK
            </button>
          </div>
        </div>
      </div>
    </div>
  );
  
  return (
    <div className="min-h-screen bg-gray-100 text-gray-800">
      {/* Navigation Bar */}
      <nav className="bg-gray-800 text-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16">
            <div className="flex items-center">
              <img className="h-8 w-auto" src="/api/placeholder/32/32" alt="Logo" />
              <div className="hidden md:block ml-10 flex items-baseline space-x-4">
                <a href="#" className="bg-gray-900 text-white px-3 py-2 rounded-md text-sm font-medium">Dashboard</a>
                <a href="#" className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">Projects</a>
                <a href="#" className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">Tests</a>
                <a href="#" className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">Devices</a>
                <a href="#" className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">Reports</a>
              </div>
            </div>
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <button className="bg-indigo-500 p-1 rounded-full text-white hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                  <Plus className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <button className="ml-3 p-1 rounded-full text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                <span className="sr-only">View notifications</span>
                <Bell className="h-6 w-6" aria-hidden="true" />
              </button>
              <div className="ml-3 relative">
                <div>
                  <button className="flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                    <span className="sr-only">Open user menu</span>
                    <User className="h-8 w-8 rounded-full" aria-hidden="true" />
                  </button>
                </div>
              </div>
              <button
                className="ml-3 md:hidden"
                onClick={() => setIsSidebarOpen(!isSidebarOpen)}
              >
                <FiMenu className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
      </nav>
  
      {/* Sidebar for mobile */}
      {isSidebarOpen && (
        <div className="fixed inset-0 flex z-40 md:hidden">
          <div className="fixed inset-0 bg-gray-600 bg-opacity-75" onClick={() => setIsSidebarOpen(false)}></div>
          <div className="relative flex-1 flex flex-col max-w-xs w-full bg-gray-800">
            <div className="absolute top-0 right-0 -mr-12 pt-2">
              <button
                className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                onClick={() => setIsSidebarOpen(false)}
              >
                <span className="sr-only">Close sidebar</span>
                <FiX  className="h-6 w-6 text-white" aria-hidden="true" />
              </button>
            </div>
            <div className="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
              <div className="flex-shrink-0 flex items-center px-4">
                <img className="h-8 w-auto" src="/api/placeholder/32/32" alt="Logo" />
              </div>
              <nav className="mt-5 px-2 space-y-1">
                <a href="#" className="bg-gray-900 text-white group flex items-center px-2 py-2 text-base font-medium rounded-md">Dashboard</a>
                <a href="#" className="text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center px-2 py-2 text-base font-medium rounded-md">Projects</a>
                <a href="#" className="text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center px-2 py-2 text-base font-medium rounded-md">Tests</a>
                <a href="#" className="text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center px-2 py-2 text-base font-medium rounded-md">Devices</a>
                <a href="#" className="text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center px-2 py-2 text-base font-medium rounded-md">Reports</a>
              </nav>
            </div>
          </div>
        </div>
      )}
  
      {/* Main Content */}
      <main className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
        {/* Dashboard Header */}
        <div className="mb-6 flex justify-between items-center">
          <h1 className="text-2xl font-semibold text-gray-900">Dashboard</h1>
          <div className="flex items-center space-x-4">
            <button 
              onClick={() => setIsDeviceModalOpen(true)}
              className="bg-indigo-600 text-white rounded-md px-4 py-2 text-sm font-medium hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Select Devices
            </button>
            <select
              value={selectedTimeRange}
              onChange={(e) => setSelectedTimeRange(e.target.value)}
              className="bg-white border rounded-md text-sm px-3 py-2 focus:outline-none focus:ring-2 focus:ring-indigo-500"
            >
              <option>Today</option>
              <option>This Week</option>
              <option>This Month</option>
              <option>Last 3 Months</option>
            </select>
          </div>
        </div>
  
        {/* Quick Stats */}
        <div className="mb-8 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4">
          {[
            { title: 'Total Projects', value: '12', icon: BarChart2, color: 'bg-blue-500' },
            { title: 'Active Tests', value: '25', icon: Activity, color: 'bg-green-500' },
            { title: 'Available Devices', value: '18', icon: Smartphone, color: 'bg-yellow-500' },
            { title: 'Test Runs (Today)', value: '142', icon: PieChart, color: 'bg-red-500' },
          ].map((stat, index) => (
            <div key={index} className="bg-white overflow-hidden shadow rounded-lg">
              <div className="p-5">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <stat.icon className={`h-6 w-6 text-white ${stat.color} p-1 rounded-full`} aria-hidden="true" />
                  </div>
                  <div className="ml-5 w-0 flex-1">
                    <dl>
                      <dt className="text-sm font-medium text-gray-500 truncate">{stat.title}</dt>
                      <dd className="text-lg font-semibold text-gray-900">{stat.value}</dd>
                    </dl>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
  
        {/* Dashboard Grid */}
        <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
          {/* Project Overview Widget */}
          <div className="bg-white overflow-hidden shadow-sm rounded-lg">
            <div className="px-4 py-5 sm:p-6">
              <div className="flex justify-between items-center mb-4">
                <h3 className="text-lg font-medium text-gray-900">Project Overview</h3>
                <button className="text-sm text-indigo-600 hover:text-indigo-800">View All</button>
              </div>
              <div className="space-y-4">
                {[
                  { name: 'Project A', status: 'Active', progress: 75 },
                  { name: 'Project B', status: 'In Progress', progress: 45 },
                  { name: 'Project C', status: 'On Hold', progress: 20 },
                ].map((project, index) => (
                  <div key={index} className="flex flex-col">
                    <div className="flex justify-between items-center mb-1">
                      <span className="font-medium">{project.name}</span>
                      <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                        project.status === 'Active' ? 'bg-green-100 text-green-800' :
                        project.status === 'In Progress' ? 'bg-yellow-100 text-yellow-800' :
                        'bg-red-100 text-red-800'
                      }`}>{project.status}</span>
                    </div>
                    <div className="w-full bg-gray-200 rounded-full h-2.5">
                      <div className="bg-blue-600 h-2.5 rounded-full" style={{ width: `${project.progress}%` }}></div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
  
          {/* Test Results Summary Widget */}
          <div className="bg-white overflow-hidden shadow-sm rounded-lg">
            <div className="px-4 py-5 sm:p-6">
              <h3 className="text-lg font-medium text-gray-900 mb-4">Test Results Summary</h3>
              <div className="flex justify-around">
                <div className="text-center">
                  <div className="text-2xl font-bold text-green-500">85%</div>
                  <div className="text-sm text-gray-500">Passed</div>
                </div>
                <div className="text-center">
                  <div className="text-2xl font-bold text-red-500">15%</div>
                  <div className="text-sm text-gray-500">Failed</div>
                </div>
              </div>
            </div>
          </div>
{/* Device Farm Status Widget */}
<div className="bg-white overflow-hidden shadow-sm rounded-lg">
  <div className="px-4 py-5 sm:p-6">
    <div className="flex justify-between items-center mb-4">
      <h3 className="text-lg font-medium text-gray-900">Device Farm Status</h3>
      <button 
        onClick={() => setIsDeviceModalOpen(true)}
        className="text-sm text-indigo-600 hover:text-indigo-800"
      >
        Manage Devices
      </button>
    </div>
    <div className="space-y-4">
      {devices.slice(0, 3).map((device) => (
        <div key={device.id} className="flex justify-between items-center">
          <div>
            <p className="text-sm font-medium text-gray-900">{device.name}</p>
            <p className="text-xs text-gray-500">{device.os} {device.version}</p>
          </div>
          <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
            device.status === 'Available' ? 'bg-green-100 text-green-800' :
            device.status === 'In Use' ? 'bg-yellow-100 text-yellow-800' :
            'bg-red-100 text-red-800'
          }`}>
            {device.status}
          </span>
        </div>
      ))}
    </div>
  </div>
</div>

  {/* Chart */}
  <div className="col-span-full mt-8">
            <div className="bg-white shadow rounded-lg p-6">
              <h2 className="text-xl font-semibold text-gray-800 mb-4">Test Execution Trend</h2>
              <ResponsiveContainer width="100%" height={300}>
                <LineChart data={data}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Line type="monotone" dataKey="tests" stroke="#8884d8" strokeWidth={2} />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </div>

{/* Activity Feed Widget */}
<div className="bg-white overflow-hidden shadow-sm rounded-lg sm:col-span-2 lg:col-span-3">
  <div className="px-4 py-5 sm:p-6">
    <div className="flex justify-between items-center mb-4">
      <h3 className="text-lg font-medium text-gray-900">Activity Feed</h3>
      <button className="text-sm text-indigo-600 hover:text-indigo-800">View All Activities</button>
    </div>
    <ul className="space-y-4">
      {[
        { user: 'John Doe', action: 'Started a new test run for Project A', time: '1h ago' },
        { user: 'Jane Smith', action: 'Updated test case TC-001 in Project B', time: '3h ago' },
        { user: 'Mike Johnson', action: 'Completed regression testing for Project C', time: '5h ago' },
        { user: 'Emily Brown', action: 'Added 3 new devices to the device farm', time: '1d ago' },
      ].map((activity, index) => (
        <li key={index} className="flex space-x-3">
          <div className="flex-shrink-0">
            <User className="h-6 w-6 text-gray-400" aria-hidden="true" />
          </div>
          <div className="flex-1 space-y-1">
            <div className="flex items-center justify-between">
              <h3 className="text-sm font-medium">{activity.user}</h3>
              <p className="text-sm text-gray-500">{activity.time}</p>
            </div>
            <p className="text-sm text-gray-500">{activity.action}</p>
          </div>
        </li>
      ))}
    </ul>
  </div>
</div>
</div>
</main>
  

      {/* Device Selection Modal */}
      {isDeviceModalOpen && <DeviceSelectionModal />}

      {/* AI Chat Interface */}
      <div className="fixed bottom-4 right-4">
        <button
          onClick={() => setIsAIChatOpen(!isAIChatOpen)}
          className="bg-indigo-600 text-white rounded-full p-3 shadow-lg hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          <HelpCircle className="h-6 w-6" aria-hidden="true" />
        </button>
      </div>

      {/* AI Chat Modal */}
      {isAIChatOpen && (
        <div className="fixed bottom-20 right-4 w-80 bg-white rounded-lg shadow-xl">
          <div className="flex justify-between items-center p-4 border-b">
            <h3 className="text-lg font-medium">Flint AI Assistant</h3>
            <button onClick={() => setIsAIChatOpen(false)}>
              <FiX className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </button>
          </div>
          <div className="p-4 h-80 overflow-y-auto">
            {/* Chat messages would go here */}
            <p className="text-gray-600">Hello Dev, How can I assist you today?</p>
          </div>
          <div className="p-4 border-t">
            <input
              type="text"
              className="w-full px-3 py-2 border rounded-md"
              placeholder="Type your message..."
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default EnhancedMobileTestingPlatform;